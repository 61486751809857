import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '@components/layout';
import GridHome from '@components/grid-home';

import HeroVideo from '@components/hero-video';
import LiveActionMasthead from '@assets/images/lookout-2025.mp4';

export default function LiveAction({ data: { contentfulGallery }, location }) {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Lookout Content</title>
        <link rel="canonical" href="https://lookoutcontent.com" />
      </Helmet>
      <HeroVideo file={LiveActionMasthead} />
      <GridHome items={contentfulGallery.items} type="work" />
    </Layout>
  );
}

export const pageQuery = graphql`
  fragment ProjectFields on ContentfulProject {
    slug
    client {
      name
    }
    title
    thumbnail {
      file {
        url
      }
    }
  }

  query {
    contentfulGallery(name: { eq: "Work" }) {
      items {
        ...ProjectFields
      }
    }
  }
`;
